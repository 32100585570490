<template>
  <div class="paginations" v-show="total > 0">
    <el-pagination
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      :page-sizes="pageSizes"
      :page-size="myPageSize"
      :current-page="myPage"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
  </div>
</template>
<script>
export default {
  name: "pagination",
  props: {
    page: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    total: {
      type: Number,
      default: 0,
    },
    pageSizes: {
      type: Array,
      default: () => [10, 20, 30, 50, 100],
    },
  },
  data() {
    return {
      myPage: this.page,
      myPageSize: this.pageSize,
    };
  },
  methods: {
    handleCurrentChange(val) {
      this.myPage = val;
      this.$emit("paging", {
        page: this.myPage,
        pageSize: this.myPageSize,
      });
    },
    handleSizeChange(val) {
      this.myPage = 1;
      this.myPageSize = val;
      this.$emit("paging", {
        page: this.myPage,
        pageSize: this.myPageSize,
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
