<template>
  <div style="width: 100%">
    <el-cascader
      size="small"
      :options="options"
      :props="{ checkStrictly: checkStrictly }"
      v-model="model"
      @change="handleChange"
      style="width: 100%"
    >
    </el-cascader>
  </div>
</template>
<script>
import { regionData } from "element-china-area-data";
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    checkStrictly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      options: regionData,
    };
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    handleChange(value) {
      this.$emit("send");
      console.log(value);
    },
  },
};
</script>
