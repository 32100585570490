<template>
  <div class="app-container" style="padding: 0">
    <el-select
      class="main-select-tree"
      ref="selectTree"
      v-model="value"
      clearable
      @clear="clearSelectInput"
      @change="handleChange"
    >
      <el-option
        v-for="item in formatData(treeData)"
        :key="item.value"
        :label="item.label"
        :value="item.value"
        style="display: none"
      />
      <el-tree
        class="main-select-el-tree"
        ref="selecteltree"
        :data="treeData"
        node-key="id"
        highlight-current
        :props="defaultProps"
        @node-click="handleNodeClick"
        :current-node-key="value"
        :expand-on-click-node="true"
        default-expand-all
      />
    </el-select>
  </div>
</template>

<script>
export default {
  props: {
    selectValue: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      filterText: "",
      treeData: [],
      defaultProps: {
        children: "children",
        label: "label",
      },
    };
  },
  computed: {
    value: {
      get() {
        return this.selectValue;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  watch: {
    value(val) {
      this.$emit("send", val);
    },
  },
  created() {
    this.getTree();
  },
  methods: {
    async getTree() {
      const { data } = await this.$http(
        "api1",
        "/api/biz/CargoTypeInfo/cargoTypeTreeList",
        "get"
      );
      console.log(data);
      if (data.code != 0) return this.$message.error(data.msg);
      this.treeData = data.data;
    },
    // 递归遍历数据
    formatData(data) {
      let options = [];
      const formatDataRecursive = (data) => {
        data.forEach((item) => {
          options.push({ label: item.label, value: item.id });
          if (item.children && item.children.length > 0) {
            formatDataRecursive(item.children);
          }
        });
      };
      formatDataRecursive(data);
      return options;
    },
    handleChange() {
      this.$emit("send", this.value);
    },
    // 点击事件
    handleNodeClick(node) {
      this.value = node.id;
      this.$refs.selectTree.blur();
      this.$emit("send", node.id);
      //this.$emit("update:selectValue", node.id);
    },
    // 清空事件
    clearSelectInput() {
      this.$emit("update:selectValue", "");
      // 获取 el-tree 实例的引用
      const elTree = this.$refs.selecteltree;
      // 将当前选中的节点设置为 null
      elTree.setCurrentKey(null);
    },
  },
};
</script>

<style>
.main-select-el-tree .el-tree-node .is-current > .el-tree-node__content {
  font-weight: bold;
  color: #409eff;
}
.main-select-el-tree .el-tree-node.is-current > .el-tree-node__content {
  font-weight: bold;
  color: #409eff;
}
</style>
